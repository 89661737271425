import React from 'react';
import {CssBaseline} from "@material-ui/core";
import {BrowserRouter} from 'react-router-dom';
import {ApolloClient, ApolloProvider, from, InMemoryCache, ServerError} from '@apollo/client';
import {IntlProvider} from "react-intl";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dateFnsEnLocale from "date-fns/locale/en-NZ";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {setContext} from "@apollo/link-context";
import {onError} from "@apollo/link-error";
import {AuthProvider} from "../auth";
import {clearToken, getToken} from '../auth/token';
import {createUploadLink} from 'apollo-upload-client';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_GRAPHQL_URL,
});

const authLink = setContext((_, {headers: originalHeaders}) => {
  const token = getToken();
  const headers = token
    ? {
      ...originalHeaders,
      Authorization: `Bearer ${token}`,
    }
    : originalHeaders;
  return {
    headers,
  }
});

const logoutLink = onError(({networkError}) => {
  if ((networkError as ServerError)?.statusCode === 401) {
    logout();
    window.location.reload();
  }
});

function logout() {
  client.clearStore();
  clearToken();
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    authLink,
    logoutLink,
    httpLink
  ]),
});

export default function AppProviders({children}: { children: React.ReactNode }) {
  return (
    <>
      <CssBaseline/>
      <IntlProvider locale="en-NZ">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsEnLocale}>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <HelmetProvider>
                <Helmet defaultTitle="NID" titleTemplate="%s | NID"/>
                <AuthProvider>
                  {children}
                </AuthProvider>
              </HelmetProvider>
            </BrowserRouter>
          </ApolloProvider>
        </MuiPickersUtilsProvider>
      </IntlProvider>
    </>
  );
}
