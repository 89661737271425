import React from 'react';
import {useAuth} from '../auth';
import AppProviders from "./AppProviders";
import SpinnerBox from "../components/SpinnerBox";

const AuthenticatedApp = React.lazy(() => import(/* webpackPrefetch: true */ './AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

function AuthAwareApp() {
  const {viewer} = useAuth();
  return (
    <React.Suspense fallback={<SpinnerBox height="100vh"/>}>
      {viewer ? <AuthenticatedApp/> : <UnauthenticatedApp/>}
    </React.Suspense>
  )
}

export default function App() {
  return (
    <AppProviders>
      <AuthAwareApp/>
    </AppProviders>
  )
}
