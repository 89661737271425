const localStorageKey = '__nid_token__';

export function setToken(token: string) {
    window.localStorage.setItem(localStorageKey, token);
}

export function clearToken() {
    window.localStorage.removeItem(localStorageKey);
}

export function getToken() {
    return window.localStorage.getItem(localStorageKey);
}
