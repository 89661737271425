import React from "react";
import {Box, CircularProgress} from "@material-ui/core";

type SpinnerBoxProps = {
  children?: never;
} & Pick<React.ComponentProps<typeof Box>, 'height'>

export default function SpinnerBox({height = '100%'}: SpinnerBoxProps) {
  return (
    <Box width="100%" height={height} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress/>
    </Box>
  );
}
